import React, { useEffect, useState } from "react";
import {
  FiArrowRight,
  FiArrowDown,
  FiChevronUp,
  FiChevronDown,
} from "react-icons/fi";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

const FeaturedWorks = ({
  isVisible,
  setIsVisible,
  setShowWorks,
  setActiveIndex,
}) => {
  const [showCategory, setShowCategory] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      const featuredWorks = document.getElementById("works");
      if (featuredWorks) {
        const featuredWorkTop = featuredWorks.offsetTop;
        const featuredWorksHeight = featuredWorks.offsetHeight;
        const featuredWorksTopHeight =
          featuredWorkTop + featuredWorksHeight - window.innerHeight;
        const winScroll =
          document.body.scrollTop || document.documentElement.scrollTop;
        if (winScroll > featuredWorkTop && winScroll < featuredWorksTopHeight) {
          isVisible && setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      }
    });
  });

  return (
    <div className="works" id="works">
      <div className="container-fluid">
        <div className="row">
          <div className="col-1 d-none d-lg-block position-relative overflow-hidden"></div>
          <div className="col-12 col-lg-10">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="heading d-flex justify-content-between align-items-center">
                    <h1>
                      Featured <span>works.</span>
                    </h1>
                    <div className="works-list">
                      <div
                        onClick={() => setShowCategory(!showCategory)}
                        className="toggler d-lg-none"
                      >
                        <span>Categories</span>
                        {showCategory ? <FiChevronUp /> : <FiChevronDown />}
                      </div>
                      {showCategory ? (
                        <>
                          <div className="categories d-flex align-items-center">
                            <button>
                              <NavLink to={"/ui-ux-design"}>
                                UI UX Design
                              </NavLink>
                            </button>
                            <button>
                              <NavLink to={"/saas-design"}>
                                SaaS Design.
                              </NavLink>
                            </button>
                            <button>
                              <NavLink to={"/motion-graphics"}>
                                Motion Graphics.
                              </NavLink>
                            </button>
                            <button>
                              <NavLink to={"/web-development"}>
                                Web Development.
                              </NavLink>
                            </button>
                          </div>
                        </>
                      ) : null}
                      <div className="categories lg-category d-flex align-items-center">
                        <button>
                          <NavLink to={"/ui-ux-design"}>UI UX Design</NavLink>
                        </button>
                        <button>
                          <NavLink to={"/saas-design"}>SaaS Design.</NavLink>
                        </button>
                        <button>
                          <NavLink to={"/motion-graphics"}>
                            Motion Graphics.
                          </NavLink>
                        </button>
                        <button>
                          <NavLink to={"/web-development"}>
                            Web Development.
                          </NavLink>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-5">
                  <div className="works-wrapper d-flex flex-column justify-content-end">
                    <div className="single-works _01">
                      <div className="works-details">
                        <div className="txt">
                          <p className="theme">Mobile App/ Product Design</p>
                          <h1>Steppen.</h1>
                          <p className="info d-none d-lg-block">
                            Designed to help you smash your fitness goal by
                            creating healthy simple fitness habits.
                          </p>
                        </div>
                        <a
                          href="https://www.behance.net/gallery/165385985/Steppen-Fitness-App"
                          className="btn case-btn"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>View case study</span>
                          <FiArrowRight />
                        </a>
                      </div>
                      <div className="works-img _01"></div>
                    </div>
                    <div className="single-works _02 mt-5">
                      <div className="works-details">
                        <div className="txt">
                          <p className="theme">WebApp / Mobile App / Website</p>
                          <h1>Zshift.</h1>
                          <p className="info d-none d-lg-block">
                            ZShift solutions help employers manage shift
                            workers. The web product (for employers) and mobile
                            app (for workers).
                          </p>
                        </div>
                        <a
                          href="https://www.behance.net/gallery/165715227/ZShift-Mobile-WebApp-Case-Study"
                          className="btn case-btn"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>View case study</span>
                          <FiArrowRight />
                        </a>
                      </div>
                      <div className="works-img _02"></div>
                    </div>
                    <div className="single-works _03 mt-5">
                      <div className="works-details">
                        <div className="txt">
                          <p className="theme">SaaS Design / WebApp</p>
                          <h1>OSIRO.</h1>
                          <p className="info d-none d-lg-block">
                            OSIRO, a dynamic user management platform built to
                            make achieving financial goals easier than ever.
                            This design highlights a seamless user journey, from
                            pre-sign-up to in-depth account management tools.
                          </p>
                        </div>
                        <NavLink
                          to="https://dribbble.com/shots/25173323-OSIRO-Financial-SaaS-Design-Pre-Sign-Up-Dashboard"
                          className="btn case-btn"
                        >
                          <span>View Project</span>
                          <FiArrowRight />
                        </NavLink>
                      </div>
                      <div className="works-img _03"></div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-5">
                  <Link
                    role="button"
                    to={"/all-works"}
                    className="btn all-work-btn"
                  >
                    <FiArrowDown />
                    <span>View all works</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-1 d-none d-lg-flex align-items-center vh-100 justify-content-end position-relative"></div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedWorks;
