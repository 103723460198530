import React from "react";
import { FaInstagram, FaLinkedinIn, FaDribbble, FaBehance } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { TbBrandFiverr } from "react-icons/tb";

const social = [
  {
    path: "https://www.instagram.com/idealrahi/",
    icon: <FaInstagram />,
  },
  {
    path: "https://www.linkedin.com/in/md-deluwar-rahi-8b6166220/",
    icon: <FaLinkedinIn />,
  },
  {
    path: "https://dribbble.com/idealrahi",
    icon: <FaDribbble />,
  },
  {
    path: "https://behance.net/idealrahi",
    icon: <FaBehance  />,
  },
  {
    path: "https://x.com/idealrahi",
    icon: <FaXTwitter />,
  },
  {
    path: "https://fiverr.com/idealruix",
    icon: <TbBrandFiverr  />,
  },
];

const RightSideSocial = () => {
  return (
    <div className="right-side-social position-fixed">
      {social.map((x, index) => (
        <a key={index} href={x.path}>{x.icon}</a>
      ))}
    </div>
  );
};

export default RightSideSocial;
