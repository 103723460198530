import React, { useState, useMemo, useEffect } from "react";
import Pagination from "../Pagination";
import data from "../../../mocks/works.json";
import Image from "../sample/Image";
import Video from "../sample/Video";
import { FiArrowRight } from "react-icons/fi";
import { ClimbingBoxLoader } from "react-spinners";

let PageSize = 12;

const MotionGraphicsProjects = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  // Filter Motion Graphics data once
  const mgData = useMemo(() => {
    return data.filter((x) => x.category === "Motion Graphics");
  }, []);

  // Get current page data
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return mgData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, mgData]);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <ul className="row portfolio-item">
        {currentTableData.map((x, index) => (
          <li key={index} className="col-lg-4 col-sm-6 mt-5">
            {loading ? (
              <div className="loaderWrap">
                <ClimbingBoxLoader color="#FFE500" />
              </div>
            ) : (
              <>
                <div className="wrap">
                  {x.videoPath ? (
                    <Video posterSrc={x.posterSrc} source={x.videoPath} />
                  ) : (
                    <Image
                      imgSrc1={x.imgSrc1 ? x.imgSrc1 : null}
                      imgSrc2={x.imgSrc2 ? x.imgSrc2 : null}
                      imgSrc3={x.imgSrc3 ? x.imgSrc3 : null}
                      imgSrc4={x.imgSrc4 ? x.imgSrc4 : null}
                      imgSrc5={x.imgSrc5 ? x.imgSrc5 : null}
                      imgSrc6={x.imgSrc6 ? x.imgSrc6 : null}
                    />
                  )}
                  {x.caseStudyPath ? (
                    <a
                      href={x.caseStudyPath}
                      target="_blank"
                      rel="noreferrer"
                      className="case-study-btn"
                    >
                      <span>View Case-Study</span>
                      <FiArrowRight />
                    </a>
                  ) : x.projectPath ? (
                    <a
                      href={x.projectPath}
                      target="_blank"
                      rel="noreferrer"
                      className="case-study-btn"
                    >
                      <span>View Project</span>
                      <FiArrowRight />
                    </a>
                  ) : null}
                </div>
                <h4 className="title">{x.category}</h4>
                <h2 className="name">{x.companyName}</h2>
              </>
            )}
          </li>
        ))}
      </ul>
      <div className="row">
        <div className="col-12 mt-5">
          <div className="pagination text-center">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={mgData.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MotionGraphicsProjects;
