import React, { useRef, useState } from "react";

const Video = ({ posterSrc, source }) => {
  const videoRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false); // State to track hover state

  const handleMouseOver = () => {
    setIsHovered(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handleMouseOut = () => {
    setIsHovered(false);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        maxWidth: "480px", // Optional: Sets a max width for the video container
        paddingBottom: "75%", // Aspect ratio: 16/12 = 4/3 = 75%
        backgroundColor: "#000", // Optional: Adds a background color
        overflow: "hidden",
      }}
    >
      {!isHovered && (
        <img
          src={posterSrc}
          alt="Poster"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: 9
          }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        />
      )}
      <video
        ref={videoRef}
        src={source}
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        muted
        loop
      ></video>
    </div>
  );
};

export default Video;
