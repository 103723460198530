import React from 'react';
import WorkPage from '../../../components/WorkPage';
import SasSDesignProjects from './SasSDesignProjects';

const SaaSDesign = () => {
    return (
        <>
            <WorkPage>
                <SasSDesignProjects />
            </WorkPage>
        </>
    );
};

export default SaaSDesign;